<template>
  <header-app></header-app>
  <router-view/>
  <footer-app></footer-app>
</template>

<script>
import HeaderApp from "./components/HeaderApp.vue";
import FooterApp from "./components/FooterApp.vue";

export default {
  name : "App",
  components : {
    HeaderApp,
    FooterApp
  },
  mounted() {
    //load curent lang
    if(localStorage.safeLang) {
      this.$i18n.locale = localStorage.safeLang;
    }    
  },
  watch: {
    '$i18n.locale' : function(newName) {
      localStorage.safeLang = newName;
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');

body {
  padding:0;
  margin: 0;
  background-color: #fff;
  font-family: "Comfortaa", cursive;
}

p {
  color:$black;
  font-weight:400;
  font-family: "Comfortaa", cursive;
  margin:0 0 20px 0;
  font-size:15px;
  line-height: 1.3em;
}

.row {
  display: flex;
  width:100%;
}

.col-20 {
  width:18%;
  margin:0 1%
}

.col-25 {
  width:23%;
  margin:0 1%
}

.col-30 {
  width:28%;
  margin:0 1%
}

.col-33 {
  width:31%;
  margin:0 1%
}

.col-40 {
  width:38%;
  margin:0 1%
}

.col-50 {
  width:48%;
  margin:0 1%;
}

.col-60 {
  width: 58%;
  margin:0 1%;
}

.col-66 {
  width: 64%;
  margin:0 1%;
}

.col-70 {
  width: 68%;
  margin:0 1%;
}

.col-25 {
  width: 23%;
  margin:0 1%
}

.col-75 {
  width: 73%;
  margin:0 1%
}

.btn {
  text-decoration: none;
  color:#fff;
  background:$mainColor;
  padding:15px 25px;
  font-size:20px;
  display: inline-block;
  transition:0.5s;
  cursor: pointer;
}

.btn:hover {
  background: darken($mainColor, 10);
  text-decoration: none;
}

.main {
  min-height: calc(100vh - 100px);
  padding-top:100px;
}

.box {
  width:$box;
  max-width: $max;
  margin:0 auto;
}

a {
  color:$mainColor;
  text-decoration: none;
  transition: 0.5s;
}

a:hover {
  text-decoration: underline;
}

/* form */

input, textarea, select {
  font-family: "Comfortaa", cursive;
  color:#333;
  border-radius: 0;
  appearance: none;
  padding:10px;
  font-size:20px;
  background:#fff;
  border:2px solid #fff;
  font-weight: 300;
}

::placeholder {
    font-family: "Comfortaa", cursive;
  color:#333;
  font-weight: 300;
}

input:focus, textarea:focus {
  outline: none;
  border: 2px solid $mainColor;
}

.form-group.error input {
    border-color:$red;
}

.error-msg {
    color:$red;
    font-size:15px;
    line-height:1.5em;
    margin-top:2px;
}

.notices {
    padding:10px;
    width:calc(100% - 20px);
    border-radius:10px;
    text-align:center;
    color:$mainColor;
    border:1px solid $mainColor;
    margin-top:20px;
    background: #fff;
    font-size:20px;
}

.notices.errors {
    color:$red;
    border-color:$red;
}

.checkbox-container {
  display:flex;
  align-items: center
}

input[type="checkbox"] {
    width:20px;
    min-width: 20px;
    height:20px;
    background:#fff;
    border:2px solid $mainColor;
    padding:0;
    margin-right:20px;
    cursor: pointer;
}

input[type="checkbox"]:checked {
    background:$mainColor;
}

.form-group.error input[type="checkbox"] {
    border:2px solid $red;
}

label {
  color:$black;
  line-height: 1.3em;
  font-weight: 300;
}

button {
    border: none;
    outline:none;
    cursor: pointer;
}

.title-about {
    text-align: center;
    margin: 40px 0 40px 0;
    color: $black;
    text-transform: uppercase;
    font-size: 35px;
    font-weight: 400;
}

.title-about span {
  color:$mainColor;
}

.focus {
  color:$mainColor;
}

@media screen and (max-width:768px) {
  .col-40, .col-60, .col-25, .col-75, .col-50, .col-33, .col-70, .col-30 {
    width:98%
  }

  .row {
    flex-direction: column;
    width:100%;
  }

  input, textarea {
    font-size: 15px;
  }

  label {
    font-size:13px;
  }

}
</style>
